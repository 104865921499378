import imageSrc from 'assets/images/man-on-bike.jpg';

export const REGISTRATION_PAGE_CONFIG = {
  PAGE_TITLE: 'Registration',
  IMAGE_SRC: imageSrc,
  FORM: {
    TITLE: 'Реєстрація',
    EMAIL_TAB: {
      TITLE: 'Електрона пошта',
      KEY: 'emailRegistrationTab',
    },
    PHONE_TAB: {
      TITLE: 'Номер телефону',
      KEY: 'phoneRegistrationTab',
    },
  },
};
