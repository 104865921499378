import imageSrc from '../../../assets/images/man-on-bike.jpg';

export const LOGIN_PAGE_CONFIG = {
  PAGE_TITLE: 'Login',
  IMAGE_SRC: imageSrc,
  FORM: {
    TITLE: 'Авторизація',
    EMAIL_TAB: {
      TITLE: 'Електрона пошта',
      KEY: 'emailLoginTab',
    },
    PHONE_TAB: {
      TITLE: 'Номер телефону',
      KEY: 'phoneLoginTab',
    },
  },
};
