export const theme = {
  primaryColor: '#003342',
  secondaryColor: '#fcffe8',
  textPrimaryColor: '#1d1617',
  textSecondaryColor: '#4f4f4f',
  success: '#75aa12',
  error: '#ea2a2a',
  fontFamily: "'Montserrat', sans-serif",
  buttonBorderRadius: 12,
};
