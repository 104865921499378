import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';

import App from './App';
import { ConfigProvider } from './components/ui/ConfigProvider';

import './index.scss';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <StrictMode>
    <HelmetProvider>
      <ConfigProvider>
        <App />
      </ConfigProvider>
    </HelmetProvider>
  </StrictMode>
);
